import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import bg from "./bg/signin.svg";
import bgimg from "./bg/backimg.jpg";
import Button from "@mui/material/Button";
import visit from "./bg/visit.jpg";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppStore } from "./appStore";
import axios from "axios";
import Stack from "@mui/material/Stack";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
    },
});

const boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const center = {
    position: "relative",
    top: "50%",
    left: "37%",
};

export default function Login() {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const updateUsername = useAppStore((state) => state.updateUsername);
    const updatePassword = useAppStore((state) => state.updatePassword);
    const updateToken = useAppStore((state) => state.updateToken);
    const updateUtype = useAppStore((state) => state.updateUtype);
    const updateUid = useAppStore((state) => state.updateUid);
    const updateUserEmail = useAppStore((state) => state.updateUserEmail);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        updateToken("");
        updateUsername("");
        updatePassword("");
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let posturl = baseURL + "login";

        await axios
            .post(posturl, {
                email: name,
                password: password,
            })
            .then(function (res) {
                console.log(res.data.user);
                updateToken(res.data.token);
                updateUsername(name);
                updateUserEmail(res.data.user.email);
                updatePassword(password);
                updateUtype(res.data.user.type);
                updateUid(res.data.user.id);
                navigate("/home");
                // Swal.fire("Submitted!", "Your file has been submitted.", "success");
            })
            .catch(function (error) {
                Swal.fire(
                    "Failed!",
                    "Please enter correct username and password!",
                    "error"
                );
                console.log("kcheckpost" + error); //return 429
            });
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${bgimg})`,
                    backgroundSize: "cover",
                    minHeight: "100vh", // Use minHeight to ensure full screen height
                    color: "#f5f5f5",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%", // Use 100% width to make it responsive
                        maxWidth: "800px", // Limit the maximum width for larger screens
                        bgcolor: "background.paper",
                        boxShadow: 24,
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundImage: `url(${visit})`,
                                    backgroundSize: "cover",
                                    marginTop: "40px",
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    minHeight: "63vh", // Use minHeight for responsive height
                                    color: "#f5f5f5",
                                }}
                            ></Box>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box
                                style={{
                                    backgroundSize: "cover",
                                    minHeight: "80vh", // Use minHeight for responsive height
                                    backgroundColor: "#3b33d5",
                                }}
                            >
                                <ThemeProvider theme={darkTheme}>
                                    <div className="flex justify-center pt-16">
                                        <div>
                                        </div>
                                    </div>
                                    <Container>
                                        <Box height={35} />
                                        <div className="flex justify-center">
                                            <div>
                                                <Typography component="h1" variant="h6">
                                                    <div className="mt-20">Admin Sign In</div>
                                                </Typography>
                                            </div>
                                        </div>

                                        <Box
                                            component="form"
                                            noValidate
                                            onSubmit={handleSubmit}
                                            sx={{ mt: 2 }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Username"
                                                        value={name}
                                                        onChange={handleNameChange}
                                                        name="email"
                                                        autoComplete="email"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        name="password"
                                                        label="Password"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                        type="password"
                                                        id="password"
                                                        autoComplete="new-password"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        size="large"
                                                        fullWidth={true}
                                                        sx={{
                                                            mt: "10px",
                                                            mr: "20px",
                                                            borderRadius: 28,
                                                            color: "#ffffff",
                                                            minWidth: "170px",
                                                            backgroundColor: "#FF9A01",
                                                        }}
                                                    >
                                                        Sign in
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </ThemeProvider>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}
