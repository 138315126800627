import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function EditForm({ fid, closeEvent }) {
    const [prize_name, setPrizeName] = useState("");
    const [prize_amount, setPrizeAmt] = useState("");
    const [statement, setStatement] = useState("");
    const baseURL = process.env.REACT_APP_API_URL;
    const updateUsers = useAppStore((state) => state.updateUsers);
    const token = useAppStore((state) => state.token);
    const statements = useAppStore((state) => state.statements);

    useEffect(() => {
        console.log("FID: ", fid);
        setPrizeName(fid.prize_name);
        setPrizeAmt(fid.prize_amount);
        setStatement(fid.statement);
    }, []);

    const createUser = async (url) => {
        let posturl = baseURL + "prize/" + fid.id;

        await axios
            .put(
                posturl,
                {
                    prize_name: prize_name,
                    prize_amount: prize_amount,
                    statement: statement,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                getUsers();
                closeEvent();
                Swal.fire("Submitted!", "Your file has been submitted.", "success");
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "prize";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateUsers(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handlePrizeNameChange = (event) => {
        setPrizeName(event.target.value);
    };
    const handlePrizeAmtChange = (event) => {
        setPrizeAmt(event.target.value);
    };
    const handleStatementChange = (event) => {
        setStatement(event.target.value);
    };


    return (
        <>
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                Edit Prize
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        error={false}
                        value={prize_name}
                        onChange={handlePrizeNameChange}
                        label="Prize Name"
                        size="small"
                        sx={{ marginTop: "10px", minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        error={false}
                        value={prize_amount}
                        onChange={handlePrizeAmtChange}
                        label="Prize Amount"
                        size="small"
                        sx={{ marginTop: "10px", minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                            minWidth: "100%",
                        }}
                    >
                        <InputLabel id="demo-simple-select-standard-label">
                            Problem Statement
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={statement}
                            onBlur={handleStatementChange}
                            onChange={handleStatementChange}
                            label="Problem Statement"
                        >
                            {statements.map((item, index) => {
                                return (
                                    <MenuItem
                                        value={item.problem_statement_title}
                                        key={index}
                                    >
                                        {item.problem_statement_title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        <Button variant="contained" onClick={createUser}>
                            Submit
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
