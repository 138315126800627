import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";

export default function AddForm({ closeEvent }) {
  const [theme_title, setThemeTitle] = useState("");
  const [theme_description, setThemeDescription] = useState("");
  const [theme_example, setThemeExample] = useState("");
  const [image, setImage] = useState("");
  const baseURL = process.env.REACT_APP_API_URL;
  const updateUsers = useAppStore((state) => state.updateUsers);
  const token = useAppStore((state) => state.token);

  const createUser = async (url) => {
    let posturl = baseURL + "theme";

    await axios
      .post(
        posturl,
        {
          theme_title: theme_title,
          theme_description: theme_description,
          theme_example: theme_example,
          image: image,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        console.log(res.data);
        getUsers();
        closeEvent();
        Swal.fire("Submitted!", "Your file has been submitted.", "success");
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };

  const getUsers = async () => {
    let posturl = baseURL + "theme";
    await axios
      .get(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        updateUsers(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const handleTitleChange = (event) => {
    setThemeTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setThemeDescription(event.target.value);
  };
  const handleExampleChange = (event) => {
    setThemeExample(event.target.value);
  };



  return (
    <>
      <Box sx={{ m: 2 }} />
      <Typography variant="h5" align="center">
        Add Theme
      </Typography>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={closeEvent}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={theme_title}
            onChange={handleTitleChange}
            label="Theme Title"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={theme_description}
            onChange={handleDescriptionChange}
            label="Theme Description"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={theme_example}
            onChange={handleExampleChange}
            label="Theme Example"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <Button variant="contained" onClick={createUser}>
              Submit
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
