import { Routes, Route, HashRouter } from "react-router-dom";
import User from "./pages/User";
import Project from "./pages/Project";
import Theme from "./pages/Theme";
import Prize from "./pages/Prize";
import ProblemStatement from "./pages/ProblemStatement";
import Login from "./Login";
import { useAppStore } from "./appStore";
import EditForm from "./problemstatement/EditForm";

export default function App() {
    const utype = useAppStore((state) => state.utype);

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path="/" exact element={<Login />}></Route>
                    {utype === "Admin" && (
                        <>
                            <Route
                                path="/home"
                                exact
                                element={<User />}
                            ></Route>
                            <Route
                                path="/project"
                                exact
                                element={<Project />}
                            ></Route>
                            <Route
                                path="/theme"
                                exact
                                element={<Theme />}
                            ></Route>
                            <Route
                                path="/problemstatement"
                                exact
                                element={<ProblemStatement />}
                            ></Route>
                            <Route
                                path="/add-problemstatement"
                                exact
                                element={<ProblemStatement />}
                            ></Route>
                            <Route
                                path="/edit-problemstatement"
                                exact
                                element={<EditForm />}
                            ></Route>
                            <Route
                                path="/prize"
                                exact
                                element={<Prize />}
                            ></Route>
                            <Route
                                path="/user"
                                exact
                                element={<User />}
                            ></Route>
                        </>
                    )}
                </Routes>
            </HashRouter>
        </>
    );
}
