import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UsersList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [formid, setFormid] = useState("");
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleEditOpen = () => setEditOpen(true);
    const handleClose = () => setOpen(false);
    const handleEditClose = () => setEditOpen(false);
    const users = useAppStore((state) => state.users);
    const updateUsers = useAppStore((state) => state.updateUsers);
    const token = useAppStore((state) => state.token);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState(null);
    const [visitorDataAll, setVisitorDataAll] = useState([]);

    useEffect(() => {
        searchData(searchQuery);
    }, [searchQuery]);

    const searchData = (searchQuery) => {
        let filteredData = users;
        if (searchQuery) {
            filteredData = users.filter((visitor) =>
                visitor.emp_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                visitor.emp_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            updateUsers(filteredData);
        }
        else {
            if (visitorDataAll.length > 1) {
                updateUsers(visitorDataAll);
            }
        }
    }

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        getUsers();
    }, []);

    const getUsers = async () => {
        let posturl = baseURL + "problemstatement";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateUsers(res.data);
                setVisitorDataAll(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "problemstatement/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                console.log(res.data);
                getUsers();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            // setRows([v]);
            updateUsers([v]);
        } else {
            getUsers();
        }
    };

    const editData = (id, s_no, organization, problem_statement_title, category, submitted_ideas, image, description, problem_statement_id, banner, theme, event_date) => {
        const data = {
            id: id,
            s_no: s_no,
            organization: organization,
            problem_statement_title: problem_statement_title,
            category: category,
            submitted_ideas: submitted_ideas,
            image: image,
            description: description,
            problem_statement_id: problem_statement_id,
            banner: banner,
            theme: theme,
            event_date: event_date,
        };
        setFormid(data);
        handleEditOpen();
    };

    return (
        <>
            <div>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <AddForm closeEvent={handleClose} />
                    </Box>
                </Modal>
                {/* <Modal
                    open={editopen}
                    // onClose={handleEditClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <EditForm closeEvent={handleEditClose} fid={formid} />
                    </Box>
                </Modal> */}
                {editopen ? (
                    <EditForm closeEvent={handleEditClose} fid={formid} />
                ) : (
                    <>
                        <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                sx={{ padding: "20px" }}
                            >
                                Problem Statement
                            </Typography>
                            <Divider />
                            <Box height={10} />
                            <Stack direction="row" spacing={2} className="my-2 mb-2">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Search Problem Statement"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="mb-2 px-2 py-1 border rounded"
                                    />
                                </div>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1 }}
                                ></Typography>
                                <Button
                                    variant="contained"
                                    endIcon={<AddCircleIcon />}
                                    onClick={handleOpen}
                                >
                                    Add
                                </Button>
                            </Stack>
                            <Box height={10} />
                            {users.length > 0 && (
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align="left"
                                                    style={{ minWidth: "100px" }}
                                                >
                                                    S. No.
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{ minWidth: "100px" }}
                                                >
                                                    Organization
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{ minWidth: "100px" }}
                                                >
                                                    Problem Statement Title
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{ minWidth: "100px" }}
                                                >
                                                    Category
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{ minWidth: "100px" }}
                                                >
                                                    Updated At
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    style={{ minWidth: "100px" }}
                                                >
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((row) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell align="left">
                                                                {row.s_no}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.organization}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.problem_statement_title}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.category}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {moment(
                                                                    row.modified_at
                                                                ).format("MMMM Do YYYY")}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Stack
                                                                    spacing={2}
                                                                    direction="row"
                                                                >
                                                                    <EditIcon
                                                                        style={{
                                                                            fontSize:
                                                                                "20px",
                                                                            color: "blue",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        className="cursor-pointer"
                                                                        onClick={() => {
                                                                            editData(
                                                                                row.id,
                                                                                row.s_no,
                                                                                row.organization,
                                                                                row.problem_statement_title,
                                                                                row.category,
                                                                                row.submitted_ideas,
                                                                                row.image,
                                                                                row.description,
                                                                                row.problem_statement_id,
                                                                                row.banner,
                                                                                row.theme,
                                                                                row.event_date,
                                                                            );
                                                                        }}
                                                                    />
                                                                    <DeleteIcon
                                                                        style={{
                                                                            fontSize:
                                                                                "20px",
                                                                            color: "darkred",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            deleteUser(
                                                                                row.id
                                                                            );
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={users.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>

                        {users.length === 0 && (
                            <>
                                <Paper
                                    sx={{
                                        width: "98%",
                                        overflow: "hidden",
                                        padding: "12px",
                                    }}
                                >
                                    <Box height={20} />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={30}
                                    />
                                    <Box height={40} />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                    <Box height={20} />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                    <Box height={20} />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                    <Box height={20} />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                    <Box height={20} />
                                    <Skeleton
                                        variant="rectangular"
                                        width={"100%"}
                                        height={60}
                                    />
                                    <Box height={20} />
                                </Paper>
                            </>
                        )}
                    </>
                )}
            </div>


        </>
    );
}
