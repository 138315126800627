import React, { useState, useEffect, useMemo, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import JoditEditor from "jodit-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";

export default function EditForm({ fid, closeEvent }) {
    const [s_no, setSerialNo] = useState("");
    const navigate = useNavigate();
    const [organization, setOrganization] = useState("");
    const [problem_statement_title, setProblemStatementTitle] = useState("");
    const [category, setCategory] = useState("");
    const [submitted_ideas, setSubmittedIdeas] = useState("");
    const [image, setImage] = useState("");
    const [description, setDescription] = useState("");
    const [problem_statement_id, setProblemStatementId] = useState("");
    const [banner, setBanner] = useState("");
    const [theme, setTheme] = useState("");
    const baseURL = process.env.REACT_APP_API_URL;
    const [event_date, setEventDate] = useState("");
    const updateUsers = useAppStore((state) => state.updateUsers);
    const token = useAppStore((state) => state.token);

    const contentconfig = useMemo(
        () => ({
            readonly: false,
            placeholder: "Enter Description",
        }),
        []
    );

    const onContentBlur = useCallback(
        (newContent) => {
            setDescription(newContent);
        },
        [description]
    );

    useEffect(() => {
        console.log("FID: ", fid);
        setSerialNo(fid.s_no);
        setOrganization(fid.organization);
        setProblemStatementTitle(fid.problem_statement_title);
        setCategory(fid.category);
        setSubmittedIdeas(fid.submitted_ideas);
        setImage(fid.image);
        setDescription(fid.description);
        setProblemStatementId(fid.problem_statement_id);
        setBanner(fid.banner);
        setEventDate(fid.event_date);
        setTheme(fid.theme);
    }, []);

    const createUser = async (url) => {
        let posturl = baseURL + "problemstatement/" + fid.id;

        await axios
            .put(
                posturl,
                {
                    s_no: s_no,
                    organization: organization,
                    problem_statement_title: problem_statement_title,
                    category: category,
                    description: description,
                    problem_statement_id: problem_statement_id,
                    theme: theme,
                    event_date: event_date,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                getUsers();
                closeEvent();
                Swal.fire("Submitted!", "Your file has been submitted.", "success");
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "problemstatement";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateUsers(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleSerialNoChange = (event) => {
        setSerialNo(event.target.value);
    };
    const handleOrganizationChange = (event) => {
        setOrganization(event.target.value);
    };
    const handleStatementTitleChange = (event) => {
        setProblemStatementTitle(event.target.value);
    };
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };
    const handleStatementIdChange = (event) => {
        setProblemStatementId(event.target.value);
    };
    const handleBannerChange = (event) => {
        setBanner(event.target.value);
    };
    const handleThemeChange = (event) => {
        setTheme(event.target.value);
    };
    const handleEventDateChange = (event) => {
        setEventDate(event.target.value);
    };



    return (
        <>
            <div className="bg-white px-5 py-5 overflow-hidden">
                <Box sx={{ m: 2 }} />
                <Box height={10} />
                <IconButton
                    style={{
                        position: "absolute",
                        top: "-10",
                        left: "20",
                    }}
                    onClick={() => {
                        closeEvent()
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5" align="center">
                    Edit Problem Statement
                </Typography>
                <IconButton
                    style={{ position: "absolute", top: "0", right: "0" }}
                    onClick={closeEvent}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            error={false}
                            value={s_no}
                            onChange={handleSerialNoChange}
                            label="S. No."
                            size="small"
                            sx={{ marginTop: "10px", minWidth: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={false}
                            value={organization}
                            onChange={handleOrganizationChange}
                            label="Organization"
                            size="small"
                            sx={{ marginTop: "10px", minWidth: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={false}
                            value={problem_statement_title}
                            onChange={handleStatementTitleChange}
                            label="Problem Statement Title"
                            size="small"
                            sx={{ minWidth: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={false}
                            value={category}
                            onChange={handleCategoryChange}
                            label="Category"
                            size="small"
                            sx={{ minWidth: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={false}
                            value={problem_statement_id}
                            onChange={handleStatementIdChange}
                            label="Statement ID"
                            size="small"
                            sx={{ minWidth: "100%" }}
                        />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <TextField
                            error={false}
                            value={theme}
                            onChange={handleThemeChange}
                            label="Theme"
                            size="small"
                            sx={{ minWidth: "100%" }}
                        />
                    </Grid> */}
                    <Grid item xs={6}>
                        <TextField
                            error={false}
                            value={event_date}
                            onChange={handleEventDateChange}
                            label="Event Date"
                            size="small"
                            sx={{ minWidth: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor="">Description</label>
                        <JoditEditor
                            value={description}
                            label="Description"
                            config={contentconfig}
                            tabIndex={1}
                            onBlur={onContentBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button variant="contained" onClick={createUser}>
                                Submit
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </div>

        </>
    );
}
