import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";

export default function AddForm({ closeEvent }) {
  const [project_title, setProjectTitle] = useState("");
  const [project_description, setProjectDescription] = useState("");
  const [image, setImage] = useState("");
  const [built_with, setBuiltWith] = useState("");
  const [video_link, setVideoLink] = useState("");
  const [presentation_link, setPresentationLink] = useState("");
  const [demo_link, setDemoLink] = useState("");
  const [repository_link, setRepositoryLink] = useState("");
  const [instructions, setInstruction] = useState("");
  const baseURL = process.env.REACT_APP_API_URL;
  const updateUsers = useAppStore((state) => state.updateUsers);
  const token = useAppStore((state) => state.token);

  const createUser = async (url) => {
    let posturl = baseURL + "project";

    await axios
      .post(
        posturl,
        {
          project_title: project_title,
          project_description: project_description,
          image: image,
          built_with: built_with,
          video_link: video_link,
          presentation_link: presentation_link,
          demo_link: demo_link,
          repository_link: repository_link,
          instructions: instructions,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        console.log(res.data);
        getUsers();
        closeEvent();
        Swal.fire("Submitted!", "Your file has been submitted.", "success");
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };

  const getUsers = async () => {
    let posturl = baseURL + "project";
    await axios
      .get(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        updateUsers(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const handleProjectTitleChange = (event) => {
    setProjectTitle(event.target.value);
  };
  const handleProjectDescriptionChange = (event) => {
    setProjectDescription(event.target.value);
  };
  const handleBuiltWithChange = (event) => {
    setBuiltWith(event.target.value);
  };
  const handleVideoLinkChange = (event) => {
    setVideoLink(event.target.value);
  };
  const handlePresentationLinkChange = (event) => {
    setPresentationLink(event.target.value);
  };
  const handleDemoLinkChange = (event) => {
    setDemoLink(event.target.value);
  };
  const handleRepositoryLinkChange = (event) => {
    setRepositoryLink(event.target.value);
  };
  const handleInstrcutionChange = (event) => {
    setInstruction(event.target.value);
  };



  return (
    <>
      <Box sx={{ m: 2 }} />
      <Typography variant="h5" align="center">
        Add Project
      </Typography>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={closeEvent}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={project_title}
            onChange={handleProjectTitleChange}
            label="Project Title"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={repository_link}
            onChange={handleRepositoryLinkChange}
            label="Repository Link"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={project_description}
            onChange={handleProjectDescriptionChange}
            label="Project Description"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={built_with}
            onChange={handleBuiltWithChange}
            label="Built With"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={video_link}
            onChange={handleVideoLinkChange}
            label="Video Link"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={presentation_link}
            onChange={handlePresentationLinkChange}
            label="Presentation Link"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={demo_link}
            onChange={handleDemoLinkChange}
            label="Demo Link"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={instructions}
            onChange={handleInstrcutionChange}
            label="Instructions"
            size="small"
            sx={{ minWidth: "100%" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <Button variant="contained" onClick={createUser}>
              Submit
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
